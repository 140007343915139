<template>
  <div class="body">
    <section class="banner">
      <div class="layout">
        <img class="img" src="../../assets/images/icon_service.png" width="240" height="240" />
        <h2>售后服务</h2>
      </div>
    </section>
  
    <div class="layout">
      <div class="content">
        <h1>售后服务承诺</h1>
        <p>1、保修期</p>
        <ul class="period">
          <li>
            <p class="main"><strong><em>3</em>年</strong> 或 <strong><em>6</em>万公里</strong></p>
            <p>整车质保<br />以先到者为准</p>
          </li>
          <li>
            <p class="main"><strong><em>5</em>年</strong> 或 <strong><em>20</em>万公里</strong></p>
            <p>动力电池包总成、<br class="mShow" />电机控制器总成、<br />驱动电机总成，以先到者为准</p>
          </li>
        </ul>
        <p>具体保修细则以豪驰汽车《保修管理规范》为准</p>
        <p v-html="'&nbsp;'"></p>
        <p>2、售后服务</p>
        <p>2.1 豪驰汽车设立400<em class="h">免费服务热线(4007991212)</em>，对用户咨询、投诉的各类问题，24小时内给予回复。</p>
        <p>2.2 售后服务项目包括车辆维修、技术咨询、备件供应等，相关服务项目由客户和豪驰汽车营销事业部具体执行。</p>
        <p>2.2.1 车辆维修，客户应依法经营、诚实守信、优质服务。主要维修项目收费价格、常用配件现行价格、维修服务流程、顾客抱怨投诉电话、组织机构图应公示在业务接待室等场所醒目位置。客户应提供汽车紧急维修救援服务,并公示服务时间、电话、收费标准。客户维修技术人员配备应满足有关要求、应按照作业规范进行维修作业。客户应根据维修服务活动和从业人员能力,制定和实施培训计划,做好培训记录。</p>
        <p>2.2.2 技术咨询，豪驰汽车营销事业部负责对客户⁄顾客的技术咨询给予答复。客户的技术经理负责向豪驰汽车进行技术咨询，指导维修人员按照要求实施故障诊断和维修工作。</p>
        <p>2.2.3 备件供应，客户向顾客提供充足的常规备件供应，稀缺备件72小时配送到位。客户应通过正当的途径采购或获取汽车零部件。客户应按照豪驰汽车指定的配件价格体系执行销售价格（易损件保修期限待定、特殊件保修期限待定、备件保修期限待定）。</p>
        <p>2.3 售后服务网络以豪驰汽车营销事业部为中心，全国各地办事处为依托，在建立销售渠道的区域，通过豪驰4S特约服务站提供服务。豪驰公司对客户进行专业培训，使其达到公司要求的售后服务标准，满足终端顾客的服务需求。在未建立销售渠道的区域，豪驰公司采用以下三种方式保证豪驰用户得到专业的售后服务：方式1—豪驰公司在当地找有维修实力的客户，经过豪驰培训并考核通过后，纳入豪驰售后服务体系；方式2—针对有自己维修渠道的豪驰用户，豪驰公司与该用户的维修渠道开展合作；方式3—如果当地没有合适的客户，豪驰公司安排专职售后人员在当地开展售后服务工作。通过以上两种售后服务模式确保为顾客提供优质、快捷、满意的服务。</p>
        <p>2.4 豪驰汽车4G模块暨远程诊断系统，由车载智能终端对汽车核心部件电池信息（电量、电压、电流、温度以及电池单体），电机信息（转速、温度），以及其它关键零部件信息进行实时采集和上传，与远程服务器通信，从而实现故障远程诊断、车辆定位及实时监控等功能。豪驰公司通过4G模块暨远程诊断系统对客户有关人员进行专业技术指导，帮助顾客进行基本的排错操作，使之成为豪驰汽车对外的技术支持平台。</p>
        <p>2.5 质量信息反馈，客户需按时向豪驰汽车营销事业部反馈质量信息，以便于豪驰公司对产品进行及时改进，同时将有效的解决方法及时传递给客户。</p>
        <p>2.6 整车和零部件（如电池）回收</p>
        <p>2.6.1 客户在回收车辆前，应先核实卖方车辆的所有权或处置权证明。</p>
        <p>2.6.2 客户与卖方进行车辆交易时应当签订合同，明确相应的责任和义务。</p>
        <p>2.6.3 为杜绝动力电池及辅助蓄电池二次污染，依据法律法规和有关规定，客户对顾客的废旧电池予以回收。</p>
        <p>2.6.4 顾客需要更换或处理废旧动力电池及辅助蓄电池，在全国各地的客户处均可给予更换或回收。</p>
        <p>2.6.5 对于顾客的废旧动力电池及辅助蓄电池由豪驰汽车与客户达成的价格进行回收。</p>
        <p>2.6.6 更换不同类别型号的动力电池或辅助蓄电池，在残余价值的基础上，另外收取不同类别型号动力电池或辅助蓄电池之间的差价。</p>
        <p>2.7 豪驰汽车对于缺陷汽车产品依有关规定履行召回义务，并承担消除缺陷的费用和必要的运输费。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Service',
};
</script>

<style scoped lang="scss">
.layout {
  padding: 0 100px;
  max-width: 1280px;
}

.banner {
  background-color: #e83e0b;

  .layout {
    display: flex;
    align-items: flex-end;
    height: 360px;

    h2 {
      padding: 80px 60px;
      font-size: 48px;
      line-height: 70px;
      color: #fff;
    }
  }
}

.content {
  padding: 100px 0;
  font-size: 16px;
  color: #222226;
  line-height: 1.5;

  h1 {
    position: relative;
    font-size: 48px;
    line-height: 3;
    color: #222226;

    &::before {
      position: absolute;
      top: 1em;
      left: -86px;
      width: 6px;
      height: 48px;
      content: '';
      overflow: hidden;
      background-color: #ff3f00;
    }
  }

  .period {
    display: flex;
    padding: 0.5em 0;

    li {
      display: flex;
      font-size: 18px;
      text-align: center;
      flex-direction: column;
      justify-content: space-between;

      & + li {
        position: relative;
        margin-left: 97px;

        &::before {
          position: absolute;
          left: -49px;
          bottom: 1px;
          width: 1px;
          height: 120px;
          content: '';
          background-color: #222226;
        }
      }

      .main {
        margin-bottom: 16px;
        font-size: 36px;

        em {
          font-size: 48px;
          font-weight: bold;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  p {
    margin: 1em 0;
  }

  .h {
    font-size: 24px;
  }
}


@media only screen and (max-width: 500px) {
  .layout {
    padding: 0 20px;
  }

  .banner {
    background-color: #e83e0b;

    .layout {
      height: 240px;

      h2 {
        padding: 36px 24px;
        font-size: 24px;
        line-height: 36px;
      }

      .img {
        width: 120px;
        height: 120px;
      }
    }
  }

  .content {
    padding: 50px 0;
    font-size: 12px;
    line-height: 1.8;

    h1 {
      font-size: 24px;

      &::before {
        left: -20px;
        width: 3px;
        height: 24px;
      }
    }

    .period {
      display: flex;
      padding: 0.5em 0;

      li {
        display: flex;
        font-size: 12px;
        line-height: 1.5;

        & + li {
          position: relative;
          margin-left: 41px;

          &::before {
            left: -21px;
            height: calc(100% - 10px);
          }
        }

        .main {
          margin-bottom: 8px;
          font-size: 18px;

          em {
            font-size: 24px;
          }
        }

        p {
          margin: 0;
        }
      }
    }

    .h {
      font-size: 16px;
    }
  }


}
</style>
